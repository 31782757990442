class Elementor_Working_Process{
    static instance;

    static getInstance() {
        if (!Elementor_Working_Process.instance) {
            Elementor_Working_Process.instance = new Elementor_Working_Process();
        }
        return Elementor_Working_Process.instance;
    }

    constructor(){
        $(window).on('elementor/frontend/init', () => {
            this.init();
        });
    }

    init(){
        elementorFrontend.hooks.addAction( 'frontend/element_ready/opal-working_process.default', ( $scope ) => {
            let $carousel = $scope.find('.owl-carousel');
            if($carousel.length > 0){
                let data = $carousel.data('settings');
                $carousel.owlCarousel(Elementor_Carousel.setupData(data));
            }
        } );
    }
}


Elementor_Working_Process.getInstance();