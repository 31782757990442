class Elementor_Nav_Menu {
    static instance;

    static getInstance() {
        if (!Elementor_Nav_Menu.instance) {
            Elementor_Nav_Menu.instance = new Elementor_Nav_Menu();
        }
        return Elementor_Nav_Menu.instance;
    }

    constructor() {
        $(window).on('elementor/frontend/init', () => {
            this.init();
        });
    }

    init() {
        elementorFrontend.hooks.addAction('frontend/element_ready/opal-nav-menu.default', ($scope) => {
            this.setupToggleButton($scope);
            this.setupMenu($scope);
            this.menuPopup($scope);
            this.menuCanvas($scope);
            this.megamenuFullWidth($scope);
        });
    }

    setupMenu($scope) {
        let subMenusMinWidth = $scope.find('nav.elementor-nav-menu--main').data('submenusminwidth');
        let subMenusMaxWidth = $scope.find('nav.elementor-nav-menu--main').data('submenusmaxwidth');
        $scope.find('.elementor-nav-menu').smartmenus({
            subIndicatorsText: '',
            subIndicatorsPos: 'append',
            subMenusMinWidth: subMenusMinWidth + 'px',
            subMenusMaxWidth: subMenusMaxWidth + 'px',
            //subMenusSubOffsetY: 100,
            showTimeout: 0,
            hideTimeout: 100,
            collapsibleBehavior: 'accordion',
        });
    }

    setupToggleButton($scope) {
        if (this.isMobileMenu($scope)) {
            // Code Button
            let $toggle = $scope.find('.elementor-menu-toggle');
            $toggle.on('click', () => {
                $toggle.toggleClass('elementor-active');
                this.toggleMenu($scope, $toggle, $toggle.hasClass('elementor-active'));
            });
        }
    }

    isMobileMenu($scope) {
        return $scope.find('.elementor-nav-menu--mobile-enable').length > 0;
    }

    toggleMenu($scope, $toggle, show) {
        let $dropdownMenu = $scope.find('.elementor-nav-menu--dropdown.elementor-nav-menu__container');
        let settings = $scope.data('settings');
        if (show) {
            $dropdownMenu.hide().slideDown(250, function () {
                $dropdownMenu.css('display', '');
            });
            if (settings.full_width) {
                $dropdownMenu.css(this.menuFullWidth($scope, $toggle, $dropdownMenu));
            }
        } else {
            $dropdownMenu.show().slideUp(250, function () {
                $dropdownMenu.attr('style', '');
            });
        }
    }

    menuFullWidth($scope, $toggle, $dropdownMenu) {
        let $container = $('body'),
            containerWidth = $container.outerWidth(),
            elementOffset = $dropdownMenu.offset().left,
            containerOffset = $container.offset().left;
        return {
            'top': $toggle.outerHeight(),
            'left': containerOffset - elementOffset + 'px',
            'width': containerWidth,
        }
    }

    megamenuFullWidth($scope) {
        let $megamenuitem = $scope.find('.has-mega-menu'),
            $parent = $scope.closest('.elementor-container'),
            $horizontal = $scope.find(' > .elementor-widget-container > .elementor-nav-menu--layout-horizontal'),
            $vertical = $scope.find(' > .elementor-widget-container > .elementor-nav-menu--layout-vertical');

        if ($horizontal.length) {
            $megamenuitem.hover(function () {
                let width = $parent.width();
                if ($(this).find('.mega-fullwidth .mega-menu-item').length) {
                    $(this).find('.mega-fullwidth .mega-menu-item').css({
                        width: width,
                        marginLeft: $parent.offset().left - $(this).offset().left
                    });
                }
                if ($(this).find('.mega-leftwidth .mega-menu-item').length) {
                    let $menuitem_first = $scope.find('  .elementor-nav-menu > li:first-child');
                    $(this).find('.mega-leftwidth .mega-menu-item').css({
                        width: width - ($menuitem_first.offset().left - $parent.offset().left),
                        marginLeft: $menuitem_first.offset().left - $(this).offset().left,

                    });
                }
                if ($(this).find('.mega-stretchwidth .mega-menu-item').length) {
                    $(this).find('.mega-stretchwidth .mega-menu-item').css({
                        width: $(window).width(),
                        marginLeft: -$(this).offset().left
                    });
                }
                if ($(this).find('.custom-subwidth .mega-menu-item').length) {
                    setTimeout(() => {
                        let itemWidth = $(this).find('.custom-subwidth .mega-menu-item').width(),
                            itemOffset = $(this).find('.custom-subwidth .mega-menu-item').offset().left;
                        if ((itemWidth + itemOffset) > $(window).width()) {
                            $(this).find('.custom-subwidth .mega-menu-item').css({
                                marginLeft: - ((itemWidth + $(this).offset().left) - $(window).width())
                            });
                        }
                    }, 50);

                }
            });
        }
        if ($vertical.length) {
            $megamenuitem.hover(function () {
                if ($(this).find('.mega-fullwidth .mega-menu-item').length) {
                    $(this).find('.mega-fullwidth .mega-menu-item').css({
                        width: $(window).width() - ($(this).offset().left + $(this).width()),
                    });
                }
                if ($(this).find('.mega-stretchwidth .mega-menu-item').length) {
                    $(this).find('.mega-stretchwidth .mega-menu-item').css({
                        width: $(window).width() - ($(this).offset().left + $(this).width()),
                    });
                }
            });
        }

        if ($scope.find(' > .elementor-widget-container > .elementor-nav-menu--layout-vertical-absolute').length) {
            $megamenuitem.hover(function () {
                if ($(this).find('.mega-fullwidth .mega-menu-item').length) {
                    $(this).find('.mega-fullwidth .mega-menu-item').css({
                        width: $(window).width() - ($(this).offset().left + $(this).width()),
                    });
                }
                if ($(this).find('.mega-stretchwidth .mega-menu-item').length) {
                    $(this).find('.mega-stretchwidth .mega-menu-item').css({
                        width: $(window).width() - ($(this).offset().left + $(this).width()),
                    });
                }
            });
        }
    }

    menuPopup($scope) {
        $scope.find('.elementor-menu-popup').magnificPopup({
            type: 'inline',
            removalDelay: 500,
            overflowY: 'hidden',
            callbacks: {
                beforeOpen: function () {
                    this.st.mainClass = this.st.el.attr('data-effect');
                },
                close: function () {
                    $scope.find('.elementor-menu-popup').removeClass('elementor-active');
                }
            },
            midClick: true
        });
    }

    menuCanvas($scope) {
        let $mp_menu = $scope.find('.elementor-nav-menu--canvas');
        let $trigger = $scope.find('.elementor-menu-toggle');
        if ($mp_menu.length > 0) {
            $mp_menu.find('ul').wrap('<div class="mp-level"></div>');
            $mp_menu.find('li.menu-item-has-children > a').append('<i class="fa fa-chevron-right trigger"></i>');
            $('<a class="mp-back text-center" href="#"><i class="fa fa-arrow-left"></i></a>').insertBefore($mp_menu.find('ul.sub-menu'));
            $mp_menu.detach().insertBefore('#page');
            new mlPushMenu($mp_menu.get(0), $trigger.get(0), {
                type: 'cover'
            });
            let section_top = $('#wpadminbar').height();
            $mp_menu.css({
                top: section_top
            })
            $(window).resize(() => {
                let section_top = $('#wpadminbar').height();
                $mp_menu.css({
                    top: section_top
                })
            })
        }
    }
}

Elementor_Nav_Menu.getInstance();