class Elementor_Progress_Circular{
    static instance;

    static getInstance() {
        if (!Elementor_Progress_Circular.instance) {
            Elementor_Progress_Circular.instance = new Elementor_Progress_Circular();
        }
        return Elementor_Progress_Circular.instance;
    }

    constructor(){
        $(window).on('elementor/frontend/init', () => {
            this.init();
        });
    }

    init(){
        elementorFrontend.hooks.addAction( 'frontend/element_ready/opal-progress-circular.default', ( $scope ) => {
            let content = $scope.find('.elementor-progress-circular-wrapper');
            let settings = content.data('settings');
            let $progress = $scope.find('.progress-circular-counter');

            let circle = new ProgressBar.Circle( $progress.get(0), {
                trailColor: settings.trailcolor,
                color: settings.color,
                duration: 1500,
                strokeWidth: 2,
                easing: 'easeOut'
            });
            circle.animate(settings.number/100);

        } );
    }
}

Elementor_Progress_Circular.getInstance();